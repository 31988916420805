<template>
	<div>
		
		<div class="d-flex align-items-end w-100 justify-content-between mb-3 header-inactive-step">
			<div class="d-flex align-items-center">
				<h3 class="mb-0">Completa l'ordine</h3>
			</div>
		</div>
		
		<div class="main-area">
			<div class="form-check mb-4">
				<input class="form-check-input" type="checkbox" value="1" id="allterms" v-model="allterms" @change="termsUpdated">
				<label class="form-check-label" for="allterms">
					Dichiaro di aver letto, compreso e di accettare <a target="_blank" :href="'/'+$route.params.teamSlug+'/terms'" class="text-secondary text-decoration-underline fw-medium">Condizioni d'uso</a>, <a target="_blank" :href="'/'+$route.params.teamSlug+'/shipping-policy'" class="text-secondary text-decoration-underline fw-medium">Condizioni di vendita</a> e <a target="_blank" :href="'/'+$route.params.teamSlug+'/refund-policy'" class="text-secondary text-decoration-underline fw-medium">Informativa sui resi</a>, oltre ad aver letto la nostra <a target="_blank" :href="'/'+$route.params.teamSlug+'/privacy-policy'" class="text-secondary text-decoration-underline fw-medium">Informativa sulla privacy</a>.
				</label>
			</div>
			
			<div v-show="allterms">
				<h5 class="fw-medium">Seleziona metodo di pagamento:</h5>
				<div v-for="item in payment_methods" class="p-3 border rounded-app mb-1">
					<div class="form-check">
						<input class="form-check-input" type="radio" :value="item.id" name="selectedpaymentmethod" :id="'selectedpaymentmethod-'+item.id" v-model="selected_payment_method">
						<label class="form-check-label fw-medium cursor-pointer d-flex w-100 align-items-center" :for="'selectedpaymentmethod-'+item.id">
							<img :src="item.iconurl" v-if="item.iconurl" class="me-3" style="height:30px" alt="" />
							{{ item.name }}
						</label>
						<div v-if="item.excerpt">{{ item.excerpt }}</div>
					</div>
				</div>
				<div style="position:relative;z-index:8">
					<div id="checkout-payment-methods-paypal-area" v-show="allterms && selected_payment_method == 'paypal'"></div>
				</div>
			</div>
		</div>
	</div>
</template>
<style scoped>
.hide-paypal-btns #checkout-payment-methods-paypal-area {
	display: none!important
}

	.inactive-step:not(.completed-step) {
		opacity: 0.5;
		display: none; /* hiding on mobile */
	}
	@media (min-width: 992px) {
		.inactive-step:not(.completed-step) {
			display: block;
		}
	}
	
	.current-step .header-inactive-step {
		display: none;
	}
	
	.inactive-step .main-area {
		display: none;
	}
	.current-step .main-area {
		display:block;
	}
	
</style>
<script>
export default {
	props: {
		payment_methods: [],
	},
  data: function() {
	return {
	  isLoading: false,
	  error_message: '',
	  
	  subscribenewsletter: 0,
	  allterms: 0,
	  selected_payment_method: '',
	}
  },
  
  methods: {
	  
	  termsUpdated: function() {
		  this.$emit('termschanged', this.allterms == 1);
	  },
	  newsletterUpdated: function() {
		this.$emit('newsletterchanged', this.subscribenewsletter == 1);
	  },
	  
	  initPaypal: function() {
		console.log('paypal loaded');
		const vm = this;
		setTimeout(function() {
			vm.$emit('allmounted');
		}, 500);
	},
	
  },
  
  mounted: function() {
	  const vm = this;
	  console.log('payment methods mounted');
	  
	  var disableFunding = [
			'sofort','mybank','paylater','bancontact','blik','eps','giropay','ideal','mercadopago','p24','sepa','sofort','venmo'
		];
		var enableFunding = [
		   'card', 'credit', 
		];
		
		var paypalclientid = 'ARD8axo0n_4JeHzo_diVkX7byrU8-bn5O6KoSxIcgfRFMSIOxntVdQA9QBxwrml968asWSPnp6Z_glFB';
		var currency = 'EUR';
		
		this.loadScript('https://www.paypal.com/sdk/js?components=buttons&client-id='+paypalclientid+'&currency='+currency+'&disable-funding='+disableFunding.join(',')+'&enable-funding='+enableFunding.join(','), 'paypalapi', 'text/javascript', 1)
		.then(res => {
			vm.initPaypal();
		});
	  
	  
	  // this.initPaypal();
	  if (this.payment_methods.length) {
			this.selected_payment_method = this.payment_methods[0].id;
	  }
	  
	  // Vue.prototype.$emitter.on('paypalloaded', function() {
		//   vm.initPaypal();
	  // });
  },
  
  beforeDestroy: function() {
	  
  },
  
}
</script>