<template>
	<div class="b5-container-sm mt-5">
		<div class="text-center b5-bg-body2 rounded-app shadow-lg mb-4 p-4">
		
			<img src="https://cdn3d.iconscout.com/3d/premium/thumb/error-404-5732790-4812666.png" alt="" class="img-fluid w-100" style="max-width:250px" />
			
			<nothing-found :settings="{
			  //icon_type: 'font-awesome',
			  //icon: 'b5-fa-icon fa-face-confused',
			  title: __('errors.http_404.title'),
			  slogan: __('errors.http_404.slogan'),
			  message: __('errors.http_404.description'),
			  size: 'large',
			}" class="pt-lg-5"></nothing-found>
			
			<router-link :to="{ name: 'home' }" class="btn btn-evidence mx-auto">
			  <i class="b5-fa-icon fa-chevron-left"></i> {{ __('gui.buttons.back_to_home') }}
			</router-link>
		</div>
		
		<!-- <a @click="$router.go(-1)" class="btn"><i class="b5-fa-icon fa-chevron-left"></i> {{ __('gui.buttons.go_back') }}</a> -->
	</div>
</template>

<script>
export default {

}
</script>