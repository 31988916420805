var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"b5-container-sm mt-5"},[_c('div',{staticClass:"text-center b5-bg-body2 rounded-app shadow-lg mb-4 p-4"},[_c('img',{staticClass:"img-fluid w-100",staticStyle:{"max-width":"250px"},attrs:{"src":"https://cdn3d.iconscout.com/3d/premium/thumb/error-404-5732790-4812666.png","alt":""}}),_vm._v(" "),_c('nothing-found',{staticClass:"pt-lg-5",attrs:{"settings":{
		  //icon_type: 'font-awesome',
		  //icon: 'b5-fa-icon fa-face-confused',
		  title: _vm.__('errors.http_404.title'),
		  slogan: _vm.__('errors.http_404.slogan'),
		  message: _vm.__('errors.http_404.description'),
		  size: 'large',
		}}}),_vm._v(" "),_c('router-link',{staticClass:"btn btn-evidence mx-auto",attrs:{"to":{ name: 'home' }}},[_c('i',{staticClass:"b5-fa-icon fa-chevron-left"}),_vm._v(" "+_vm._s(_vm.__('gui.buttons.back_to_home'))+"\n\t\t")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }