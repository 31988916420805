<template>
	<div id="router-view">
		<transition mode="out-in" name="custom-classes-transition animate__animated"
		enter-active-class="animate__tada"
		leave-active-class="animate__backOutDown">
			<router-view v-slot="{ Component, route }" v-on:loading="mainLoading" v-on:loaded="mainLoaded" class="d-block w-100">
				<transition :name="route.meta.transition || 'fade'" mode="out-in">
					<component :is="Component" />
				</transition>
			</router-view>
		</transition>
	</div>
</template>
<script>
export default {
	data: function() {
		return {
			isLoading: false,
		};
	},
  
	methods: {
		moment: function(date=null) {
			if (date) {
				return (date == 'as_object') ? moment : moment(date);
			}

			return moment();
		},
		
		mainLoading: function() {
			
		},
		
		mainLoaded: function() {
			
		},
	},
};
</script>