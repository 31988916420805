var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.data)?_c('div',[_c('div',{class:_vm.layout!='modal' ? 'bg-body-secondary rounded-app p-3 mb-5' : ''},[(_vm.data.items && _vm.data.items.length)?_c('div',{staticClass:"list-group list-group-flush"},_vm._l((_vm.data.items),function(item){return _c('div',{staticClass:"list-group-item px-0"},[_c('div',{staticClass:"d-flex w-100 justify-content-between align-items-center"},[_c('div',{staticClass:"me-2 bg-cover rounded",style:('width:32px;height:32px;background-image:url('+item.product.cc_thumbnail_720p_url+')')}),_vm._v(" "),_c('div',{staticClass:"w-50 text-start"},[_c('b',[_vm._v(_vm._s(item.product.name))])]),_vm._v(" "),_c('div',{staticClass:"w-50 text-end"},[_vm._v("\n\t\t\t\t\t\t\t"+_vm._s(_vm.formatCurrency(item.product.price, item.product.currency, 'it-IT', 1))+"\n\t\t\t\t\t\t\t"),_c('span',{staticClass:"text-secondary"},[_vm._v("x"+_vm._s(item.quantity))])])])])}),0):_c('div',{staticClass:"text-center text-secondary p-3"},[_vm._v("\n\t\t\t\tNon hai biglietti nel carrello\n\t\t\t")]),_vm._v(" "),_c('hr',{staticClass:"my-1"}),_vm._v(" "),(_vm.data.total)?_c('div',{staticClass:"list-group list-group-flush"},[_c('div',{staticClass:"list-group-item d-flex w-100 justify-content-between px-0"},[_c('div',{staticClass:"fw-bold"},[_vm._v("Totale")]),_vm._v(" "),_c('div',{staticClass:"fw-bold"},[_vm._v(_vm._s(_vm.formatCurrency(_vm.data.total.price, _vm.data.currency, 'it-IT', 1)))])])]):_vm._e(),_vm._v(" "),(_vm.data.total)?_c('div',{staticClass:"p-3 bg-body-tertiary rounded-app mt-4"},[_c('div',{staticClass:"mb-1 fw-medium"},[_vm._v("Inserisci il tuo indirizzo email per completare l'ordine:")]),_vm._v(" "),_c('div',{staticClass:"form-floating has-validation"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.cart_email),expression:"cart_email"}],staticClass:"form-control form-control-lg",class:{
					  'is-invalid': !_vm.cart_email || !_vm.isValidEmail(_vm.cart_email),
					  'is-valid': _vm.cart_email && _vm.isValidEmail(_vm.cart_email)
				  },attrs:{"type":"email","id":"floatingInputemailaddress","placeholder":"marco@rossi.it"},domProps:{"value":(_vm.cart_email)},on:{"input":function($event){if($event.target.composing)return;_vm.cart_email=$event.target.value}}}),_vm._v(" "),_c('label',{attrs:{"for":"floatingInputemailaddress"}},[_vm._v("Indirizzo email")])]),_vm._v(" "),_vm._l((_vm.data.inputs),function(input){return _c('div',{staticClass:"pt-3"},[(input == 'customer_name')?_c('div',{staticClass:"form-floating has-validation"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.customer_name),expression:"customer_name"}],staticClass:"form-control form-control-lg",class:{
						  'is-invalid': !_vm.is_valid_customer_name,
						  'is-valid': _vm.is_valid_customer_name
					  },attrs:{"type":"text","id":"floatingInputcart_customer_name","placeholder":"es. Marco Rossi"},domProps:{"value":(_vm.customer_name)},on:{"input":function($event){if($event.target.composing)return;_vm.customer_name=$event.target.value}}}),_vm._v(" "),_c('label',{attrs:{"for":"floatingInputcart_customer_name"}},[_vm._v("Nome completo")])]):(input == 'codice_fiscale')?_c('div',[_c('div',{staticClass:"form-floating has-validation"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.codice_fiscale),expression:"codice_fiscale"}],staticClass:"form-control form-control-lg text-uppercase",class:{
							  'is-invalid': !_vm.is_valid_codice_fiscale,
							  'is-valid': _vm.is_valid_codice_fiscale
						  },attrs:{"type":"text","id":"floatingInputcart_codice_fiscale","placeholder":"es. Marco Rossi"},domProps:{"value":(_vm.codice_fiscale)},on:{"input":function($event){if($event.target.composing)return;_vm.codice_fiscale=$event.target.value}}}),_vm._v(" "),_c('label',{attrs:{"for":"floatingInputcart_codice_fiscale"}},[_vm._v("Codice fiscale")])]),_vm._v(" "),_c('div',{staticClass:"form-text"},[_vm._v("Necessario ai fini fiscali")])]):_vm._e()])})],2):_vm._e(),_vm._v(" "),(_vm.layout == 'modal' && _vm.data.items && _vm.data.items.length)?_c('button',{staticClass:"btn btn-primary btn-lg rounded-pill fw-bold shadow w-100 mt-3 mb-2 bg-gradient",attrs:{"disabled":!_vm.isFormSubmittable(),"type":"button"},on:{"click":function($event){return _vm.goToCheckout()}}},[_vm._v("\n\t\t\t\tCompleta l'ordine "),_c('i',{staticClass:"fas fa-fw fa-arrow-right"})]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"form-text text-center"},[_vm._v("\n\t\t\t  Sarai indirizzato alla pagina di scelta del metodo di pagamento\n\t\t\t")])])]):_c('div',[(_vm.isloading)?_c('div',{staticClass:"placeholder-glow"},_vm._l((5),function(i){return _c('div',{staticClass:"mb-1 placeholder rounded bg-secondary d-block py-3"})}),0):(_vm.error)?_c('div',[_vm._v("\n\t\t\t"+_vm._s(_vm.error)+"\n\t\t")]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }