require('./bootstrap');

import moment from 'moment-timezone';
var abbrs = {
	EST : 'Eastern Standard Time',
	EDT : 'Eastern Daylight Time',
	CST : 'Central Standard Time',
	CDT : 'Central Daylight Time',
	MST : 'Mountain Standard Time',
	MDT : 'Mountain Daylight Time',
	PST : 'Pacific Standard Time',
	PDT : 'Pacific Daylight Time',
};

moment.fn.zoneName = function () {
	var abbr = this.zoneAbbr();
	return abbrs[abbr] || abbr;
};
window.moment = moment;

window.Vue = require('vue').default;
import * as b5AppConfig from './config.js';
window.bundle_version = b5AppConfig.b5_bundle_version;

if ('scrollRestoration' in window.history) {
  window.history.scrollRestoration = 'manual';
}
Vue.mixin(require('./trans'));

import router from './router';
import App from './layouts/App.vue';

if (b5AppConfig.ambient == 'production') {
  Vue.config.devtools = false; // disable vuejs chrome extension in production
 // Pusher.logToConsole = false;
}
else {
  // Enable pusher logging - don't include this in production
  Vue.config.devtools = true; // disable vuejs chrome extension in production
 // Pusher.logToConsole = true;
}
	
const components = require.context('./components/', true, /\.vue$/i)
components.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], components(key).default)) // Sync load

const elementsPage = require.context('./elements/Page/', true, /\.vue$/i)
elementsPage.keys().map(key => Vue.component('elementsPage'+key.split('/').pop().split('.')[0], elementsPage(key).default))

import mitt from 'mitt';
const emitter = mitt();

Vue.prototype.$globalData = Vue.observable({
	test: 'ciaone',
});

const app_name = "Bizday.it";

const app = new Vue({
   router,
   el: '#app',
   render: h => h(App),
   created: function() {
	   console.log('%c Attenzione!', 'color: red; font-size: 3rem; font-weight: bold');
	   console.log('%c Questa funzione del browser è pensata per gli sviluppatori. Se ti è stato detto di copiare qualcosa qui per abilitare una funzione di Build5 o "compromettere" l\'account di qualcuno, in realtà si tratta di una truffa che fornirà a queste persone l\'accesso al tuo account Build5.', 'font-size: 1.5rem');
   },
});