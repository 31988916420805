<template>
	<div class="countdown-elements d-flex justify-content-around align-items-center">
		<div v-if="ct_days" class="px-2 py-2 lh-1 text-center rounded mx-1 flex-fill coutdown-card-days" v-bind:class="getColorScheme()">
			<div class="countdown-fill-days fs-3 fw-bold mb-0">
				{{ ct_days }}
			</div>
			<span class="text-sm">giorni</span>
		</div>
		<div class="px-2 py-2 lh-1 text-center rounded mx-1 flex-fill coutdown-card-hours" v-bind:class="getColorScheme()">
			<div class="countdown-fill-hours fs-3 fw-bold" data-amount="0">
				{{ ct_hours }}
			</div>
			<span class="text-sm">ore</span>
		</div>
		<div class="px-2 py-2 lh-1 text-center rounded mx-1 flex-fill coutdown-card-minutes" v-bind:class="getColorScheme()">
			<div class="countdown-fill-minutes fs-3 fw-bold" data-amount="0">
				{{ ct_mins }}
			</div>
			<span class="text-sm">min</span>
		</div>
		<div class="px-2 py-2 lh-1 text-center rounded mx-1 flex-fill coutdown-card-seconds" v-bind:class="getColorScheme()">
			<div class="countdown-fill-seconds fs-3 fw-bold" data-amount="0">
				{{ ct_secs }}
			</div>
			<span class="text-sm">sec</span>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		settings: null,
	},
	data: function() {
		const defaultConfig = {
			to_date: null,
			to_time: null,
		};
		return {
			config: {...defaultConfig, ...this.settings},
			interv: null,
			ct_days: 0,
			ct_hours: 0,
			ct_mins: 0,
			ct_secs: 0,
		};
	},
	
	methods: {
		getColorScheme: function() {
			if (this.config.color_scheme == 'dark') {
				return 'bg-dark text-white';
			}
			else {
				return 'bg-white text-dark';
			}
		},
		
		moment: function(date=null) {
			if (date) {
				return (date == 'as_object') ? moment : moment(date);
			}

			return moment();
		},
		
		updateTimer: function() {
			const vm = this;
			const interval = 1000;
			const eventTime = this.moment( (vm.config.to_date+' '+vm.config.to_time, 'UTC').trim() ); // Timestamp - Sun, 21 Apr 2013 13:00:00 GMT
			const now = this.moment().utc(); // Timestamp - Sun, 21 Apr 2013 12:30:00 GMT
			
			vm.ct_days = eventTime.diff(now, 'days');
			
			const diffTime = eventTime.unix() - now.unix();
			const duration = moment.duration(diffTime*1000, 'milliseconds');
			const diff = moment.duration(duration - interval, 'milliseconds');
			vm.ct_hours = diff.hours();
			vm.ct_mins = diff.minutes();
			vm.ct_secs = diff.seconds();
		}
	},
	
	mounted() {
		const vm = this;
		this.updateTimer();
		this.interv = setInterval(this.updateTimer, 1000);
	},
	
	beforeDestroy: function() {
		if (this.interv) {
			clearInterval(this.interv);
		}
		this.interv = null;
	},
}
</script>