<template>
	<div>
		<div v-if="data">
			<div v-bind:class="layout!='modal' ? 'bg-body-secondary rounded-app p-3 mb-5' : ''">
				<div v-if="data.items && data.items.length" class="list-group list-group-flush">
					<div v-for="item in data.items" class="list-group-item px-0">
						<div class="d-flex w-100 justify-content-between align-items-center">
							<div class="me-2 bg-cover rounded" :style="'width:32px;height:32px;background-image:url('+item.product.cc_thumbnail_720p_url+')'"></div>
							<div class="w-50 text-start">
								<b>{{ item.product.name }}</b>
							</div>
							
						
							<div class="w-50 text-end">
								{{ formatCurrency(item.product.price, item.product.currency, 'it-IT', 1) }}
								<span class="text-secondary">x{{ item.quantity }}</span>
							</div>
						</div>
					</div>
				</div>
				<div v-else class="text-center text-secondary p-3">
					Non hai biglietti nel carrello
				</div>
				
				<hr class="my-1">
				
				<div v-if="data.total" class="list-group list-group-flush">
					<!-- <div class="list-group-item d-flex w-100 justify-content-between">
						<div>Subtotale</div>
						<div>{{ formatCurrency(data.total.price, data.currency, 'it-IT', 1) }}</div>
					</div> -->
					<div class="list-group-item d-flex w-100 justify-content-between px-0">
						<div class="fw-bold">Totale</div>
						<div class="fw-bold">{{ formatCurrency(data.total.price, data.currency, 'it-IT', 1) }}</div>
					</div>
				</div>
				
				<div v-if="data.total" class="p-3 bg-body-tertiary rounded-app mt-4">
					<div class="mb-1 fw-medium">Inserisci il tuo indirizzo email per completare l'ordine:</div>
					<div class="form-floating has-validation">
					  <input type="email" v-model="cart_email" class="form-control form-control-lg" v-bind:class="{
						  'is-invalid': !cart_email || !isValidEmail(cart_email),
						  'is-valid': cart_email && isValidEmail(cart_email)
					  }" id="floatingInputemailaddress" placeholder="marco@rossi.it">
					  <label for="floatingInputemailaddress">Indirizzo email</label>
					</div>
					
					<div v-for="input in data.inputs" class="pt-3">
						<div v-if="input == 'customer_name'" class="form-floating has-validation">
						  <input type="text" v-model="customer_name" class="form-control form-control-lg" v-bind:class="{
							  'is-invalid': !is_valid_customer_name,
							  'is-valid': is_valid_customer_name
						  }" id="floatingInputcart_customer_name" placeholder="es. Marco Rossi">
						  <label for="floatingInputcart_customer_name">Nome completo</label>
						</div>
						<div v-else-if="input == 'codice_fiscale'">
							<div class="form-floating has-validation">
							  <input type="text" v-model="codice_fiscale" class="form-control form-control-lg text-uppercase" v-bind:class="{
								  'is-invalid': !is_valid_codice_fiscale,
								  'is-valid': is_valid_codice_fiscale
							  }" id="floatingInputcart_codice_fiscale" placeholder="es. Marco Rossi">
							  <label for="floatingInputcart_codice_fiscale">Codice fiscale</label>
							</div>
						  <div class="form-text">Necessario ai fini fiscali</div>
						</div>
					</div>
				</div>
				
				<button v-if="layout == 'modal' && data.items && data.items.length" @click="goToCheckout()" v-bind:disabled="!isFormSubmittable()" type="button" class="btn btn-primary btn-lg rounded-pill fw-bold shadow w-100 mt-3 mb-2 bg-gradient">
					Completa l'ordine <i class="fas fa-fw fa-arrow-right"></i>
				</button>
				<div class="form-text text-center">
				  Sarai indirizzato alla pagina di scelta del metodo di pagamento
				</div>
			</div>
		</div>
		<div v-else>
			<div v-if="isloading" class="placeholder-glow">
				<div v-for="i in 5" class="mb-1 placeholder rounded bg-secondary d-block py-3"></div>
			</div>
			<div v-else-if="error">
				{{ error }}
			</div>
		</div>
	</div>
</template>
<script>
export default {
	props: ['checkoutkey', 'layout'],
	data: function() {
		return {
			data: null,
			isloading: false,
			error: null,
			
			customer_name: null,
			cart_email: null,
			
			is_valid_customer_name: true,
			is_valid_codice_fiscale: true,
			codice_fiscale: null,
		};
	},
	
	watch: {
		codice_fiscale: function(val) {
			if (this.data.inputs && this.data.inputs.includes('codice_fiscale')) {
				this.is_valid_codice_fiscale = val && val.replace(/\s/g, '').length > 3;
			}
			else {
				this.is_valid_codice_fiscale = true; // campo non richiesto
			}
		},
		
		customer_name: function(val) {
			if (this.data.inputs && this.data.inputs.includes('customer_name')) {
				this.is_valid_customer_name = val && val.replace(/\s/g, '').length > 3;
			}
			else {
				this.is_valid_customer_name = true; // campo non richiesto
			}
		},
	},
	
	methods: {
		
		isFormSubmittable: function() {
			var emailvalid = this.cart_email && this.isValidEmail(this.cart_email);
			
			return (
				emailvalid && this.is_valid_customer_name && this.is_valid_codice_fiscale
			);
		},
		
		goToCheckout: function() {
			const vm = this;
			if (! this.isFormSubmittable()) return;
			
			this.$emit('opencheckout', {
				key: this.checkoutkey,
				email: vm.cart_email,
				customer_name: vm.customer_name,
				codice_fiscale: vm.codice_fiscale,
			});
		},
		
		getCheckoutKey: function() {
			return this.checkoutkey;
		},
		
		loadCard: function(key) {
			if (!this.isloading) {
				if (!key) {
					this.error = 'checkout key not found';
					return;
				}
				
				const vm = this;
				this.isloading = true;
				axios.get('/api/v2/checkout/'+key)
				.then(res => {
					vm.data = res.data;
					vm.is_valid_customer_name = true;
					vm.is_valid_codice_fiscale = true;
					
					if (vm.data.inputs && vm.data.inputs.includes('customer_name')) {
						vm.is_valid_customer_name = false;
						
						if (vm.getCookie('checkout-customer_name')) { // lo compilo solo se mi è richiesto
							vm.customer_name = atob(vm.getCookie('checkout-customer_name'));
						}
					}
					
					if (vm.data.inputs && vm.data.inputs.includes('codice_fiscale')) {
						vm.is_valid_codice_fiscale = false;
						
						if (vm.getCookie('checkout-codice_fiscale')) { // lo compilo solo se mi è richiesto
							vm.codice_fiscale = atob(vm.getCookie('checkout-codice_fiscale'));
						}
					}
				})
				.catch(err => {
					vm.error = err;
				})
				.finally(res => {
					vm.isloading = false;
				});
			}
		}
	},
	
	mounted: function() {
		const vm = this;
		this.loadCard(this.getCheckoutKey());
		
		if (this.getCookie('checkout-email')) {
			this.cart_email = atob(this.getCookie('checkout-email'));
		}
	},
}
</script>