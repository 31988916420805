import Vue from 'vue';
import VueRouter from 'vue-router';

// Errors
import Error404 from './pages/error/404.vue';

Vue.use(VueRouter);

function lazyLoadRoute(view) {
  return() => import(`./pages/${view}.vue`);
}

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	//base: '/',
	linkActiveClass: "router-link-active",
	linkExactActiveClass: "router-link-exact-active active",
	routes: [
		{
			path: '/',
			name: 'home',
			component: lazyLoadRoute('Home'),
			meta: { transition: 'slide-left' },
		},
		// {
		// 	path: '/order/:orderid/pay',
		// 	name: 'order.pay',
		// 	component: lazyLoadRoute('order/Pay'),
		// 	meta: { transition: 'slide-left' },
		// },
		// {
		// 	path: '/scuola-supervisori',
		// 	name: 'scuola-supervisori',
		// 	component: lazyLoadRoute('Scuolasupervisori'),
		// 	meta: { transition: 'slide-left' },
		// },
		// {
		// 	path: '/prossimo-evento',
		// 	name: 'prossimo-evento',
		// 	component: lazyLoadRoute('Home'),
		// 	meta: { transition: 'slide-left' },
		// },
		{
			path: '/event/:eventSlug',
			name: 'event.show',
			component: lazyLoadRoute('event/Show'),
			meta: { transition: 'slide-left' },
		},
		// {
		// 	path: '/cart/:cartKey',
		// 	name: 'cart',
		// 	component: lazyLoadRoute('Cart'),
		// 	meta: { transition: 'slide-left' },
		// },
		// {
		// 	path: '/checkout/:checkoutKey',
		// 	name: 'checkout',
		// 	component: lazyLoadRoute('Checkout'),
		// 	meta: { transition: 'slide-left' },
		// },
		
		// Errors
		{ path: "*", component: Error404 },
		{ path: '/:catchAll(.*)', component: Error404 }
	],
	
	scrollBehavior (to, from, savedPosition) {
	  // return desired position
	  if (to.hash) {
		return {
		  selector: to.hash
		  // , offset: { x: 0, y: 10 }
		};
	  }
	  
	  if (savedPosition) {
		return savedPosition;
	  } else {
		return { x: 0, y: 0 };
	  }
	}
});

router.beforeEach((to, from, next) => { // Serve per fixare il redirect che fa Facebook Login (sia logged che da guest) aggiungeo #_=_ alla url
  if (to.path == '/_=_') {
	return next(false);
  }

  next();
});

router.afterEach((to, from, failure) => {
  const toDepth = to.path.split('/').length
  const fromDepth = from.path.split('/').length
  to.meta.transition = toDepth < fromDepth ? 'slide-right' : 'slide-left'
  
	// gtag('config', window.GA_TRACKING_ID, {
	//   page_path: to.fullPath,
	//   app_name: 'IO Build5',
	//   send_page_view: true,
	// });
	
  
  if (typeof gtag !== "undefined") { // se ha già caricato analytics e la funzione esiste
	//gtag('send', 'pageview');
  }
});

export default router;