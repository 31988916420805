<template>
	<div>
		<div class="d-flex align-items-end w-100 justify-content-between mb-3 header-inactive-step">
			<div class="d-flex align-items-center">
				<div class="w-100">
					<h3 class="mb-0">Indirizzo di fatturazione</h3>
					<p class="text-secondary mb-0">Le seguenti informazioni sono necessarie ai fini fiscali</p>
				</div>
				
				<!-- <div class="completed-mark ms-2">
					<i class="fas fa-fw fa-check text-success fs-5"></i>
				</div> -->
			</div>
			
			<!-- <button type="button" class="btn btn-ghost text-decoration-underline btn-sm fw-normal edit-step-btn" @click="editStep()">
				Modifica
			</button> -->
		</div>
		
		<div class="main-area">
			<!-- <div class="form-check mb-4 mt-3">
				<input class="form-check-input" type="checkbox" value="1" v-model="use_same_shipping_address" id="use_same_shipping_address">
				<label class="form-check-label" for="use_same_shipping_address">
					Utilizza lo stesso indirizzo di spedizione
				</label>
			</div> -->
			
			<div v-show="!use_same_shipping_address">
				<div class="row g-3 mb-3">
					<div class="col-12 col-lg-6">
						<div class="form-floating">
							<input type="text" class="form-control" v-bind:class="isValidField('billing_first_name').valid ? '' : 'is-invalid'" 
							autocomplete="given-name" @keyup="billing_first_name_edited=true" placeholder="Nome" v-model="billing_first_name">
							<label>Nome</label>
						</div>
						<div v-if="!isValidField('billing_first_name').valid" class="invalid-feedback">
							{{ isValidField('billing_first_name').message }}
						</div>
					</div>
					<div class="col-12 col-lg-6">
						<div class="form-floating">
							<input type="text" class="form-control" placeholder="Cognome" v-model="billing_last_name" 
							autocomplete="family-name" v-bind:class="isValidField('billing_last_name').valid ? '' : 'is-invalid'" @keyup="billing_last_name_edited=true">
							<label>Cognome</label>
						</div>
						<div v-if="!isValidField('billing_last_name').valid" class="invalid-feedback">
							{{ isValidField('billing_last_name').message }}
						</div>
					</div>
				</div>
			</div>
			
			<div class="mb-3">
				<div class="form-floating">
					<input type="text" class="form-control" placeholder="es. marcorossi@gmail.com" v-model="billing_email" v-bind:class="isValidField('billing_email').valid ? '' : 'is-invalid'" autocomplete="email" @keyup="billing_email_edited=true">
					<label>Indirizzo email</label>
				</div>
				<div v-if="!isValidField('billing_email').valid" class="invalid-feedback">
					{{ isValidField('billing_email').message }}
				</div>
			</div>
			
			<div class="mb-3">
				<div class="row g-0 align-items-stretch">
					<div class="col-4 col-lg-3">
						<button v-if="selected_phone_prefix" type="button" class="btn btn-ghost d-block w-100 h-100 border" @click="selected_phone_prefix=null">
							<span :class="'fi rounded border me-1 fi-'+selected_phone_prefix.code.toLowerCase()">
								<span class="visually-hidden">{{ selected_phone_prefix.code }}</span>
							</span>
							{{ selected_phone_prefix.dial_code }}
							
							<i class="fas fa-fw fa-edit ms-1"></i>
						</button>
						<div v-else>
							<div class="form-floating">
								<input type="text" class="form-control" placeholder="+39" @keyup="billing_phone_edited=true" v-model="filter_phone_prefix" v-bind:class="isValidField('billing_phone_prefix').valid ? '' : 'is-invalid'">
								<label>Prefisso</label>
							</div>
							<ul class="list-group overflow-auto border shadow" style="max-height:180px" v-if="filtered_phone_prefixes.length">
								<li v-for="prefix in filtered_phone_prefixes" @click="selectPhonePrefix(prefix)" class="list-group-item list-group-item-action cursor-pointer">
									<span :class="'fi rounded border me-1 fi-'+prefix.code.toLowerCase()">
										<span class="visually-hidden">{{ prefix.code }}</span>
									</span>
									{{ prefix.dial_code }}
								</li>
							</ul>
						</div>
					</div>
					<div class="col-8 col-lg-9">
						<div class="form-floating">
							<input type="text" class="form-control" placeholder="+39" minlength="6" maxlength="30" v-model="billing_phone" v-bind:class="isValidField('billing_phone').valid ? '' : 'is-invalid'" @keyup="billing_phone_edited=true" autocomplete="tel">
							<label>Numero di telefono</label>
						</div>
					</div>
				</div>
				<div v-if="!isValidField('billing_phone').valid" class="invalid-feedback">
					{{ isValidField('billing_phone').message }}
				</div>
				<!--
				<div class="form-text">
					<!-- <div v-if="billing_phone">
						Formato: minimo 9 massimo 12 numeri (senza spazio)
						<br>
						Aggiungi il prefisso del paese (es. <b>+39</b>123456789)
					</div>
					Ti invitiamo ad inserire un numero di cellulare per ricevere le notifiche
				</div> -->
			</div>
			
			<div v-if="show_cf">
				<div class="mb-3">
					<div class="input-group">
						<div class="form-floating">
							<input type="text" class="form-control text-uppercase" placeholder="" v-bind:class="isValidField('billing_cf').valid ? '' : 'is-invalid'" @keyup="billing_cf_edited=true" v-model="billing_cf">
							<label>Codice fiscale <span v-if="!require_cf">(opzionale)</span></label>
						</div>
						<button type="button" class="btn btn-evidence" @click="showBSModal('modal-calcola-cf')">Calcola</button>
					</div>
					
					<div v-if="!isValidField('billing_cf').valid" class="invalid-feedback">
						{{ isValidField('billing_cf').message }}
					</div>
				</div>
				
				<div class="modal fade" id="modal-calcola-cf" tabindex="-1" aria-labelledby="modal-calcola-cf-label" aria-hidden="true">
					<div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
						<div class="modal-content">
							<div class="modal-header py-2">
								<h1 class="modal-title fs-5" id="modal-calcola-cf-label">Calcola codice fiscale</h1>
								<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
							</div>
							<div class="modal-body pb-0">
								<div class="alert alert-info mb-3 text-center">Calcola in modo semplice e veloce il tuo codice fiscale</div>
								<calcolatore-codice-fiscale v-if="cfcalculatorkey" class="mb-3" :key="cfcalculatorkey" :data="{
									firstname: billing_first_name,
									lastname: billing_last_name,
								}" @receive="calcolaCodiceFiscale" />
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<div v-show="!use_same_shipping_address">
				<div class="mb-3">
					<div class="form-floating">
						<input type="text" class="form-control" placeholder="" v-model="billing_address_1" autocomplete="address-line1" id="billing-address-autocomplete">
						<label>Indirizzo</label>
					</div>
				</div>
				
				<div class="form-floating mb-3">
					<input type="text" class="form-control" placeholder="Indirizzo 2" v-model="billing_address_2" autocomplete="address-line2">
					<label>Opzionale - Azienda, C/O, App, Interno, Unità</label>
				</div>
				<div class="row g-3 mb-3">
					<div class="col-6">
						<div class="form-floating">
							<input type="text" class="form-control" placeholder="" v-model="billing_address_postalcode" autocomplete="postal-code">
							<label>Codice postale</label>
						</div>
					</div>
					<div class="col-6">
						<div class="form-floating">
							<input type="text" class="form-control" placeholder="" v-model="billing_address_city" autocomplete="address-level2">
							<label>Città</label>
						</div>
					</div>
					<div class="col-6">
						<div class="form-floating">
							<input type="text" class="form-control" placeholder="" v-model="billing_address_province">
							<label>Stato/provincia</label>
						</div>
					</div>
					<div class="col-6">
						<div class="form-floating">
							<select class="form-select" v-model="billing_address_countrycode">
								<option v-for="country_code in ['IT']" :value="country_code">
									{{ getCountryName(country_code) }}
								</option>
							</select>
							<label>Paese/regione</label>
						</div>
					</div>
				</div>
			</div>
			
			<div class="text-end">
				<button v-if="isLoading" class="btn btn-black rounded-pill" type="button" disabled>
					<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
					<span class="visually-hidden">Loading...</span>
				</button>
				<button v-else type="button" class="btn btn-primary fw-medium rounded-pill d-block w-100 btn-lg" @click="completeBillingInfo()" v-bind:disabled="!canSubmitBillingInfo()">
					{{ cta_label }}
				</button>
			</div>
		</div>
	
	</div>
</template>
<script>
export default {
	props: {
		phone_prefixes: [],
		next_step: {
			type: String,
			default: 'payment_method',
		},
		show_cf: false,
		require_cf: false,
	},
  data: function() {
	return {
		cta_label: 'Vai al pagamento',
		cfcalculatorkey: 'cfcalculator'+this.makeid(12),
	  isLoading: false,
	  error_message: '',
	  autocomplete_billing: null,
	  
	  use_same_shipping_address: false,
	  
	  billing_cf: null,
	  billing_cf_edited: false,
	  
	  billing_first_name: null,
	  billing_first_name_edited: false, // indica se il campo è mai stato modificato. di default no = non mostra i messaggi d'errore a fe finchè l'utente non inizia a modificare il campo oppure prova a submittare la form
	  billing_last_name: null,
	  billing_last_name_edited: false,
	  billing_email: null,
	  billing_email_edited: false,
	  billing_phone_prefix: null,
	  billing_phone: null,
	  billing_phone_edited: false,
	  
		billing_address_1: null,
		billing_address_2: null,
		billing_address_city: null,
		billing_address_province: null,
		billing_address_postalcode: null,
		billing_address_countrycode: 'IT',
		
		selected_phone_prefix: null,
		filter_phone_prefix: null,
	}
  },
  
  computed: {
	  filtered_phone_prefixes: function() {
		  if (this.phone_prefixes && Array.isArray(this.phone_prefixes) && this.phone_prefixes.length) {
				if (this.filter_phone_prefix) {
					const vm = this;
					return this.phone_prefixes.filter(function(el) {
						return el.dial_code.includes(vm.filter_phone_prefix);
					});
				}
		  }
		  else if (this.filter_phone_prefix) { // questo consente di accettare il prefisso anche se per qualche motivo la lista dei prefissi non è caricata
			  this.billing_phone_prefix = this.filter_phone_prefix;
		  }
		  
		  return [];
	  },
  },
  
  methods: {
	  
	  calcolaCodiceFiscale: function(val) {
		  this.billing_cf = val;
		  this.hideBSModal('modal-calcola-cf');
	  },
	  
	  selectPhonePrefix: function(data) {
		  this.selected_phone_prefix = data;
		  this.billing_phone_prefix = data.dial_code;
		  this.filter_phone_prefix = data.dial_code;
	  },
	  
	  editStep: function() {
		  this.$emit('editing');
	  },
	  
	  isValidBillingCountry: function() {
			if (this.billing_address_countrycode == 'IT') {
				return true;
			}
			
			return false;
		},
	  
	  canSubmitBillingInfo: function() {
		  
		  return (
			  (this.use_same_shipping_address && this.billing_phone && this.billing_email)
			  ||
			  (
				  !this.use_same_shipping_address 
				  && this.billing_address_1 && this.billing_address_city && this.billing_address_postalcode && this.billing_address_province 
				  && this.billing_phone 
				  && this.billing_first_name && this.billing_last_name && this.billing_email 
				  && this.isValidBillingCountry()
				  
				  && this.isValidField('billing_first_name').valid
				   && this.isValidField('billing_last_name').valid
				   && this.isValidField('billing_email').valid
				   && this.isValidField('billing_phone').valid
				   && this.isValidField('billing_phone_prefix').valid
				   
				   && ((this.require_cf && this.billing_cf && this.isValidField('billing_cf').valid) || !this.require_cf)
			  )
		  );
	  },
	  
	  completeBillingInfo: function() {
		 this.isLoading = true;
		 this.error_message = '';
		this.billing_first_name_edited = true;
		this.billing_last_name_edited = true;
		this.billing_phone_edited = true;
		this.billing_email_edited = true;
		this.billing_cf_edited = true;
		
		this.cfcalculatorkey='cfcalculator'+this.makeid(12); // aggiorno la chiave del calcolatore codice fiscale così si basa su nome e cognome inseriti dall'utente
		 
		 if (
			 this.canSubmitBillingInfo()
			 
			 // && this.isValidField('billing_first_name').valid
			 // && this.isValidField('billing_last_name').valid
			 // && this.isValidField('billing_email').valid
			 // && this.isValidField('billing_phone').valid
			 // && this.isValidField('billing_phone_prefix').valid
		 ) {
			   this.error_message = '';
			   
			   this.$emit('completed', {
				   use_same_shipping_address: this.use_same_shipping_address,
				   first_name: this.billing_first_name,
				   last_name: this.billing_last_name,
				   phone: this.billing_phone,
				   phone_prefix: this.billing_phone_prefix,
				   email: this.billing_email,
				   address1: this.billing_address_1,
				   address2: this.billing_address_2,
				   city: this.billing_address_city,
				   postalcode: this.billing_address_postalcode,
				   province: this.billing_address_province,
				   countrycode: this.billing_address_countrycode,
				   cf: this.billing_cf,
			   });
			   
			   // se sto ri-modificando l'indirizzo di fatturazione
			   // e avevo messo il flag a Usa stesso indirizzo per la spedizione
			   // aggiorno l'indirizzo di spedizione con quello di fatturazione
			   // 
			   // al momento non l'ho ancora fatto perchè torno allo step dell'indirizzo di spedizione dopo che modifico la fatturazione, quindi ho comunque un eventuale riconferma dell'indirizzo
		 }
		 else {
			 this.error_message = 'Compila tutti i campi richiesti per la fatturazione prima di continuare';
		 }
		 
		 this.isLoading = false;
	  },
	
	isValidField: function(field) {
		if (field == 'billing_cf') {
			if (! this.show_cf) {
				return {
					valid: true,
				};
			}
			else if (! this.require_cf) {
				return {
					valid: true,
				};
			}
			else if (this.require_cf && this.billing_cf) {
				return {
					valid: true,
				};
			}
			else if (!this.billing_cf_edited) {
				return {
					valid: true,
				};
			}
		}
		else if (field == 'billing_email') {
			if (!this.billing_email_edited) {
				return {
					valid: true,
				};
			}
			if (this.isValidEmail(this.billing_email)) {
				return {
					valid: true,
				};
			}
		}
		else if (field == 'billing_phone_prefix') {
			if (!this.billing_phone_edited) {
				return {
					valid: true,
				};
			}
			if (this.phone_prefixes && Array.isArray(this.phone_prefixes) && this.phone_prefixes.length) {
				if (this.selected_phone_prefix && this.selected_phone_prefix.dial_code == this.billing_phone_prefix) {
					return {
						valid: true,
					};
				}
			}
			else if (this.billing_phone_prefix) { // questo consente di accettare il prefisso anche se per qualche motivo la lista dei prefissi non è caricata
				return {
					valid: true,
				};
			}
		}
		else if (field == 'billing_phone') {
			if (!this.billing_phone_edited) {
				return {
					valid: true,
				};
			}
			
			var regex = /^[0-9 ()+-]+$/;
			
			if (this.billing_phone !== null && this.billing_phone != '' && this.billing_phone.trim().length >= 7 && regex.test(this.billing_phone)) {
				return {
					valid: true,
				};
			}
		}
		else {
			if (!this[field+'_edited']) {
				return {
					valid: true,
				};
			}
			else {
				if (this[field] !== null && this[field] !== '' && this[field].trim().length >= 2) {
					return {
						valid: true,
					};
				}
			}
		}
		
		return {
			valid: false,
			message: 'Campo obbligatorio',
		};
	},
	
	initAutocomplete: function() {
		console.log('billaddr maps loaded');
		var options = {
			fields: ["address_components", "geometry"],
			types: ["address"],
		};
		
		// Restringo l'indirizzo di fatturazione ai paesi validi per il country attuale del negozio
		// if (Vue.prototype.$globalData.websitedata.shop.current_country.billing_countries_restriction.length) {
		// 	options.componentRestrictions = { country: Vue.prototype.$globalData.websitedata.shop.current_country.billing_countries_restriction };
		// }
		
		var el1 = document.querySelector("#billing-address-autocomplete");
		if (el1 && typeof google !== 'undefined' && google) {
			this.autocomplete_billing = new google.maps.places.Autocomplete(el1, options);
			//this.autocomplete_billing.inputid = "billing-address-autocomplete";
			this.autocomplete_billing.addListener("place_changed", this.fillBillingAddress);
		}
	},
	
	fillBillingAddress: function() {
		const vm = this;
	  // Get the place details from the autocomplete object.
	  const place = this.autocomplete_billing.getPlace();
		let streetnum = "";
		let routeadd = "";
		let postcode = "";
	  
		// Get each component of the address from the place details,
		// and then fill-in the corresponding field on the form.
		// place.address_components are google.maps.GeocoderAddressComponent objects
		// which are documented at http://goo.gle/3l5i5Mr
		for (const component of place.address_components) {
		  // @ts-ignore remove once typings fixed
		  const componentType = component.types[0];
	  
		  switch (componentType) {
			case "street_number": {
			  streetnum = component.long_name;
			  break;
			}
	  
			case "route": {
			  routeadd = component.short_name;
			  break;
			}
	  
			case "postal_code": {
			  postcode = `${component.long_name}${postcode}`;
			  break;
			}
	  
			case "postal_code_suffix": {
			  postcode = `${postcode}-${component.long_name}`;
			  break;
			}
			case "locality":
				vm.billing_address_city = component.long_name;
			  break;
			case "administrative_area_level_2": { // era administrative_area_level_1
				vm.billing_address_province = component.short_name;
			  break;
			}
			case "country":
				vm.billing_address_countrycode = component.short_name;
			  break;
		  }
		}
		vm.billing_address_1 = routeadd+' '+streetnum;
		vm.billing_address_postalcode = postcode;
	},
	
  },
  
  mounted: function() {
	  
	  this.loadScript('https://maps.googleapis.com/maps/api/js?key=AIzaSyDd-zExQOrmc1fdXgABqjnpEvsXUUhrddI&libraries=places&v=weekly', 'googlemapsapi');
	  
	  
	  console.log('bill addr mounted');
	  this.initAutocomplete();
	  
	  if (this.next_step == 'shipping_info') {
		  this.cta_label = 'Vai alla spedizione';
	  }
	  else if (this.next_step == 'extra_fields') {
		this.cta_label = 'Continua';
	}
	  else if (this.next_step == 'payment_method') {
		this.cta_label = 'Vai al pagamento';
	}
	  
	  if (this.phone_prefixes) {
		  var matches = this.phone_prefixes.filter(function(el) {
			  return el.code == 'IT';
		  });
		  if (matches && matches.length) {
				this.selected_phone_prefix = matches[0]; // pre-seleziono di base il primo valore possibile giusto per far capire che si tratta di un prefisso nazionale
			  this.selectPhonePrefix(matches[0]);
		  }
		}
  },
  
  beforeDestroy: function() {
	  
  },
  
}
</script>