<template>
	<div class="elements--post-category--alert b5-container-xl">
		test
	</div>
</template>

<script>
	export default {
		props: {
			instance: null
		},
		
		data: function() {
			return {
				data: {}
			};
		},
		
		methods: {
			
		},
		
		created: function() {
			const vm = this;

		},
		
		mounted() {
		}
	}
</script>