<template>
	<div>
		<div v-if="data">
			<!-- <div v-if="step != 'cart'" class="b5-bg-body3 mb-4">
				<div class="b5-container-sm px-0">
					<ul id="events-tabs" role="tablist" class="d-flex w-100 flex-nowrap overflow-auto nav b5-nav-ios with-dividers mb-2 bg-none justify-content-center">
						<li role="presentation" class="nav-item">
							<button @click="step = 'cart'" v-bind:class="step == 'cart' ? 'active' : ''" type="button" class="nav-link btn-sm fw-medium py-1">Carrello</button>
						</li>
						<li role="presentation" class="nav-item">
							<button @click="step = 'fiscali'" v-bind:class="step == 'fiscali' ? 'active' : ''" type="button" class="nav-link btn-sm fw-medium py-1">Su di me</button>
						</li>
						<li role="presentation" class="nav-item">
							<button @click="step = 'pagamento'" v-bind:class="step == 'pagamento' ? 'active' : ''" type="button" class="nav-link btn-sm fw-medium py-1">Riepilogo</button>
						</li>
					</ul>
				</div>
			</div> -->
			
			<div v-if="step == 'cart' || 1==1" v-bind:class="layout!='modal' ? 'bg-body-secondary rounded-app p-3 mb-5' : ''">
				<div v-if="data.items.length" class="list-group list-group-flush">
					<div v-for="item in data.items" class="list-group-item px-0">
						<div class="d-flex w-100 justify-content-between align-items-center">
							<div class="me-2 bg-cover rounded" :style="'width:32px;height:32px;background-image:url('+item.product.cc_thumbnail_720p_url+')'"></div>
							<div class="w-100">
								<b>{{ item.product.name }}</b>
								<span class="text-secondary">{{ item.quantity }}x</span>
							</div>
							
						
							<div class="">{{ item.product.formatted_price }}</div>
						</div>
					</div>
				</div>
				<div v-else class="text-center text-secondary p-3">
					Non hai biglietti nel carrello
				</div>
				
				<hr class="my-1">
				
				<div class="list-group list-group-flush">
					<!-- <div class="list-group-item d-flex w-100 justify-content-between">
						<div>Subtotale</div>
						<div>{{ formatCurrency(data.total.price, data.currency, 'it-IT', 1) }}</div>
					</div> -->
					<div class="list-group-item d-flex w-100 justify-content-between px-0">
						<div class="fw-bold">Totale</div>
						<div class="fw-bold">{{ formatCurrency(data.total.price, data.currency, 'it-IT', 1) }}</div>
					</div>
				</div>
				
				<div class="p-3 bg-body-tertiary rounded-app mt-4">
					<div class="mb-1 fw-medium">Inserisci il tuo indirizzo email per completare l'ordine:</div>
					<div class="form-floating has-validation">
					  <input type="email" v-model="cart_email" class="form-control form-control-lg" v-bind:class="{
						  'is-invalid': !cart_email || !isValidEmail(cart_email),
						  'is-valid': cart_email && isValidEmail(cart_email)
					  }" id="floatingInput" placeholder="marco@rossi.it">
					  <label for="floatingInput">Indirizzo email</label>
					</div>
				</div>
				
				<button v-if="layout == 'modal' && data.items.length" @click="goToCheckout()" v-bind:disabled="!cart_email || !isValidEmail(cart_email)" type="button" class="btn btn-primary btn-lg rounded-pill fw-bold shadow w-100 mt-3 mb-2 bg-gradient">
					Completa l'ordine <i class="fas fa-fw fa-arrow-right"></i>
				</button>
				<div class="form-text text-center">
				  Sarai indirizzato alla pagina di scelta del metodo di pagamento
				</div>
			</div>
			<div v-if="step == 'billing_info'">
				<checkout-billing-address
					:phone_prefixes="phone_prefixes || []"
					@editing="billingAddressEditing"
					@completed="billingAddressCompleted"
					next_step="pagamento"
					:require_cf="data.require_cf"
					show_cf="1"
					/>
			</div>
			<div v-else-if ="step == 'payment_method'">
				<checkout-payment-method
					@allmounted="mountPaypal"
					@termschanged="(val) => allterms=val"
					@newsletterchanged="(val) => subscribenewsletter=val"
					:payment_methods="data.payment_methods"
					/>
			</div>
		</div>
		<div v-else class="container py-5">
			<div v-if="isloading" class="placeholder-glow">
				<div class="mb-3 ratio ratio-4x3" v-for="i in 5">
					<div class="placeholder"></div>
				</div>
			</div>
			<div v-else-if="error">
				{{ error }}
			</div>
		</div>
	</div>
</template>
<script>
export default {
	props: ['checkoutkey','current_step', 'layout'],
	data: function() {
		return {
			cart_email: null,
			
			temp_data: null,
			data: null,
			isloading: false,
			error: null,
			step: 'cart',
			
			phone_prefixes: [],
			step: 'cart',
			
			
			
			
			initDone: false,
			  
			  show_spinner: false,
			 // current_step: 'cart',
			  error_message: '',
			  autocomplete_billing: null,
			  autocomplete_shipping: null,
			  
				show_success: 0,
				success_animation: '',
				
				gmapsloaded: 0,
				paypalloaded: 0,
				
				selected_vls: null,
			  
			  cart_cached: [],
			  
			  // Keypress api call timeout
			  keyPressTimeout: null,
			  
			  extra_fields_completed: false,
			  
			  billing_completed: false,
			  billing_first_name: null,
			  billing_cf: null,
			  billing_first_name_edited: false, // indica se il campo è mai stato modificato. di default no = non mostra i messaggi d'errore a fe finchè l'utente non inizia a modificare il campo oppure prova a submittare la form
			  billing_last_name: null,
			  billing_last_name_edited: false,
			  billing_email: null,
			  billing_email_edited: false,
			  billing_phone_prefix: null,
			  billing_phone: null,
			  billing_phone_edited: false,
			  
			  use_same_shipping_address: false,
				billing_address_1: null,
				billing_address_2: null,
				billing_address_city: null,
				billing_address_province: null,
				billing_address_postalcode: null,
				billing_address_countrycode: null,
				
				shipping_completed: false,
				ship_to_billing_address: 0,
				shipping_first_name: null,
				shipping_last_name: null,
				shipping_address_1: null,
				shipping_address_2: null,
				shipping_address_city: null,
				shipping_address_province: null,
				shipping_address_postalcode: null,
				shipping_address_countrycode: null,
				shipping_note: null,
				
				isLoadingAddress: false,
				address_suggestions: [],
				
				allterms: 0,
				subscribenewsletter: 0,
				
				payment_method: '',
			  
			  paypalButtonsVisible: true,
			  
			  order: null,
			  
			  showAdvisoryPopup: 1,
			  showCartRiepilogo: 0,
			  
			  preventrequestlimits: 0,
		};
	},
	
	watch: {
		
	},
	
	methods: {
		
		goToCheckout: function() {
			const vm = this;
			// current_step='billing_info'
			this.$emit('opencheckout', {
				key: this.checkoutkey,
				email: vm.cart_email,
			});
		},
		
		mountPaypal: function() {
			this.paypalScriptLoaded();
		},
		
		paypalScriptLoaded: function(plan=null) {
			const vm = this;
			
			if (!window.hasOwnProperty('paypal')) {
				console.warn('paypal not loaded');
				return;
			}
			else {
				this.paypalloaded=1;
			}
			
			console.log('paypal script loaded!');
			
			window.paypal.Buttons({
				createOrder() {
					  vm.show_spinner = true;
					  vm.order = null;
					vm.error_message = '';
				  return fetch('/api/v6/shop/create-paypal-order', {
					method: "POST",
					headers: {
						'Access-Control-Allow-Origin': '*',
						"Content-Type": "application/json",
						Authorization: 'Bearer ' + document.head.querySelector('meta[name="csrf-token"]').content,
						'X-CSRF-Token': document.head.querySelector('meta[name="csrf-token"]').content
					},
					// use the "body" param to optionally pass additional order information
					// like product skus and quantities
					body: JSON.stringify({
						billing_address: {
							first_name: vm.billing_first_name,
							last_name: vm.billing_last_name,
							email: vm.billing_email,
							phone: vm.billing_phone_prefix+' '+vm.billing_phone,
							cf: vm.billing_cf,
							
							address_line1: vm.billing_address_1,
							address_line2: vm.billing_address_2,
							city: vm.billing_address_city,
							postal_code: vm.billing_address_postalcode,
							province: vm.billing_address_province,
							country: vm.billing_address_countrycode,
						},
						shipping_address: {
							first_name: vm.shipping_first_name,
							last_name: vm.shipping_last_name,
							address_line1: vm.shipping_address_1,
							address_line2: vm.shipping_address_2,
							city: vm.shipping_address_city,
							postal_code: vm.shipping_address_postalcode,
							province: vm.shipping_address_province,
							country: vm.shipping_address_countrycode,
						},
						shipping_driver: vm.selected_vls ? vm.selected_vls.id : null,
						shipping_note: vm.shipping_note,
						cart_key: vm.checkoutkey,
						country_code: vm.getCurrentCountryCode(),
						use_sandbox: vm.data.use_sandbox==1 ? 1 : 0,
					  // cart: [
						// {
						//   sku: plan?.id ?? "product_sku",
						//   quantity: 1,
						// },
					  // ],
					}),
				  })
				  .then((response) => response.json())
				  .then(function(order) {
						vm.show_spinner = false;
					  if (order && order.hasOwnProperty('message')) {
						vm.manageErrors(order.message);
						  window.scrollTo(0,0); // scroll to top per vedere l'errore
					  }
					return order.id;
				  });
				},
				
				onCancel(data) {
					// Show cancel page, or return to cart,
					vm.order = null;
					  window.scrollTo(0,0); // scroll to top per vedere l'errore
				},
				
				onError(data) {
					console.warn('errore paypal', data);
					vm.error_message = data;
					vm.paypalButtonsVisible = true;
					vm.order = null;
					
					if (data && data.hasOwnProperty('message')) {
						vm.manageErrors(data.message);
					}
					  window.scrollTo(0,0); // scroll to top per vedere l'errore
				},
				
				onApprove(data) {
					vm.order = null;
					vm.order = {
						id: data.orderID,
					};
					  // console.log('orderid: '+data.orderID);
					  // console.log(vm.order.id || data.orderID);
					  vm.show_spinner = true;
				  return fetch("/api/v6/shop/"+vm.$route.params.teamSlug+"/capture-paypal-order", {
					method: "POST",
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json',
						Authorization: 'Bearer ' + document.head.querySelector('meta[name="csrf-token"]').content,
						'X-CSRF-Token': document.head.querySelector('meta[name="csrf-token"]').content
					},
					body: JSON.stringify({
					  order_id: vm.order.id || data.orderID,
					  subscribe_newsletter: vm.subscribenewsletter ? 1 : 0,
					  use_sandbox: vm.data.use_sandbox==1 ? 1 : 0,
					})
				  })
				  .then((response) => response.json())
				  .then((orderData) => {
					  // console.log('ecco');
					  // console.log(orderData);
					  window.scrollTo(0,0); // scroll to top
					  
					  vm.show_spinner = false;
					  const defaultErrorMessage = 'Si è verificato un errore inaspettato durante il pagamento. Per favore, riprova più tardi o contattaci tramite la live chat.';
					  
					  if (orderData.status == 'success' && orderData.res.status == 'COMPLETED') {
						  vm.paypalButtonsVisible = false;
						  // Successful capture! For dev/demo purposes:
						  //console.log('Capture result', orderData, JSON.stringify(orderData, null, 2));
						  //const transaction = orderData.purchase_units[0].payments.captures[0];
						  vm.showSuccessAnimation();
						  vm.error_message = 'success';
						  Vue.prototype.$globalData.footer.visible = 1;
						  vm.order = orderData.order;
						  //alert(`Transaction ${transaction.status}: ${transaction.id}\n\nSee console for all available details`);
						  // When ready to go live, remove the alert and show a success message within this page. For example:
						  // const element = document.getElementById('paypal-button-container');
						  // element.innerHTML = '<h3>Thank you for your payment!</h3>';
						  // Or go to another URL:  window.location.href = 'thank_you.html';
						  
						  vm.$parent.setCartKey(null); // dimentico la cart key
						  vm.$parent.loadCart(); // ricarico il carrello che sarà a questo punto vuoto
						  window.scrollTo(0,0); // scroll to top
					  }
					  else { // Qualche tipo di errore
						  window.scrollTo(0,0); // scroll to top per vedere l'errore
						  vm.error_message = orderData.status;
						  
						  if (orderData.hasOwnProperty('message')) {
							  vm.error_message = orderData.message || defaultErrorMessage;
						  }
						  else {
							  if (orderData.res?.message) {
								  vm.error_message = orderData.res.message || defaultErrorMessage;
							  }
							  else {
								  vm.error_message = defaultErrorMessage;
							  }
						  }
					  }
					
				  });
				}
			  }).render('#checkout-payment-methods-paypal-area');
			
		},
		
		getCurrentCountryCode: function() {
			return 'it-IT';
		},
		
		manageErrors: function(error_message) {
			console.log('manageErrors', error_message);
			
			if (error_message == 'Expected an order id to be passed') {
				this.error_message = error_message;
			}
			
			if (error_message == 'Cart is empty' || error_message == 'Cart not found' || error_message == 'Cart not found 1' || error_message == 'Cart not found 2') { // refresho tutto e riparto da zero
				//this.$parent.resetCart();
				if (this.preventrequestlimits >= 1) {
					//this.loadData();
				}
				this.preventrequestlimits++;
			}
			else {
				window.scrollTo(0,0); // scroll to top per vedere l'errore
				this.error_message = error_message;
			}
		},
		
		
		billingAddressEditing: function() {
			this.step='billing_info';
			this.billing_completed=false;
			  this.extra_fields_completed = false;
			this.shipping_completed=false;
		},
		  
		billingAddressCompleted: function(data) {
			this.step = 'payment_method';
			
			// if (data.use_same_shipping_address) {
			// 	this.billing_first_name = this.shipping_first_name;
			// 	this.billing_last_name = this.shipping_last_name;
			// 	this.billing_address_1 = this.shipping_address_1;
			// 	this.billing_address_2 = this.shipping_address_2;
			// 	this.billing_address_city = this.shipping_address_city;
			// 	this.billing_address_province = this.shipping_address_province;
			// 	this.billing_address_postalcode = this.shipping_address_postalcode;
			// 	this.billing_address_countrycode = this.shipping_address_countrycode;
			// }
			// else {
				this.billing_cf = data.cf;
				this.billing_first_name = data.first_name;
				this.billing_last_name = data.last_name;
				this.billing_address_1 = data.address1;
				this.billing_address_2 = data.address2;
				this.billing_address_city = data.city;
				this.billing_address_province = data.province;
				this.billing_address_postalcode = data.postalcode;
				this.billing_address_countrycode = data.countrycode;
			//}
			this.billing_email = data.email;
			this.billing_phone = data.phone ? data.phone.replace(/\s/g, "") : data.phone;
			this.billing_phone_prefix = data.phone_prefix;
			
			//this.scrolltoId('payment-methods-area');
		},
		
		
		
		
		
		getCheckoutKey: function() {
			return this.checkoutkey;
			return this.$route.params.checkoutKey;
		},
		
		loadCard: function(key) {
			if (!this.isloading) {
				if (!key) {
					this.error = 'checkout key not found';
					return;
				}
				
				console.log('Carico', this.getCheckoutKey());
				
				const vm = this;
				this.isloading = true;
				axios.get('/api/v1/checkout/'+key)
				.then(res => {
					vm.temp_data = res.data;
					axios.get("/phoneprefixes.json")
					.then(resphone  => {
						vm.phone_prefixes = resphone.data;
					})
					.finally(res => {
						vm.data = vm.temp_data;
						vm.temp_data = null;
					});
				})
				.catch(err => {
					vm.error = err;
				})
				.finally(res => {
					vm.isloading = false;
				});
			}
		}
	},
	
	mounted: function() {
		const vm = this;
		this.step = this.current_step;
		this.loadCard(this.getCheckoutKey());
		
		if (this.getCookie('checkout-email')) {
			this.cart_email = this.getCookie('checkout-email');
		}
	},
}
</script>